import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WithAdmin, WithCUR, WithNotCMP } from '@hooks/hoc';
import { SuspenseBox, SuspensePaper } from '@components/styled/suspense';
import { PaymentArea, PaymentHistory } from './components';

const SettingsPaymentPage: React.FC = () => {
  const { t } = useTranslation('settings');
  return (
    <>
      <SuspenseBox>
        <PaymentArea />
      </SuspenseBox>
      <Typography variant="subtitle2" color="textSecondary" mt={6} mb={1}>
        {t('payment.title.history')}
      </Typography>
      <SuspensePaper sx={{ px: 7, py: 8 }}>
        <PaymentHistory />
      </SuspensePaper>
    </>
  );
};

export default WithNotCMP(WithAdmin(WithCUR(SettingsPaymentPage)));
