import React, { useCallback, useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { List } from '@mui/material';
import { useToggle } from '@hooks/common';
import { ROUTES } from '@routes';
import { useCurrentInfo } from '@hooks/api/common';
import { CollapsibleNavItem, DefaultNavItem } from './MainSidebar.styled';

const MainSidebar: React.FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [reportOpen, toggleReport] = useToggle();
  const [optimizationOpen, toggleOptimization] = useToggle();
  const [anomalyOpen, toggleAnomaly] = useToggle();
  const [settingsOpen, toggleSettings] = useToggle();
  const [adminOpen, toggleAdmin] = useToggle();

  const closeAll = useCallback(() => {
    if (reportOpen) {
      toggleReport();
    }
    if (optimizationOpen) {
      toggleOptimization();
    }
    if (anomalyOpen) {
      toggleAnomaly();
    }
    if (settingsOpen) {
      toggleSettings();
    }
    if (adminOpen) {
      toggleAdmin();
    }
  }, [
    reportOpen,
    optimizationOpen,
    anomalyOpen,
    settingsOpen,
    adminOpen,
    toggleReport,
    toggleOptimization,
    toggleAnomaly,
    toggleSettings,
    toggleAdmin,
  ]);
  const navigateTo = useCallback(
    (to: string) => {
      navigate(to);
      onClose();
      closeAll();
    },
    [navigate, onClose, closeAll],
  );
  useEffect(() => {
    if (!isOpen) {
      closeAll();
    } else {
      if (pathname.startsWith('/report') && !reportOpen) {
        toggleReport();
      }
      if (
        (pathname.startsWith('/optimization') ||
          pathname.startsWith('/details')) &&
        !optimizationOpen
      ) {
        toggleOptimization();
      }
      if (pathname.startsWith('/anomaly') && !anomalyOpen) {
        toggleAnomaly();
      }
      if (pathname.startsWith('/settings') && !settingsOpen) {
        toggleSettings();
      }
    }
  }, [
    anomalyOpen,
    closeAll,
    isOpen,
    optimizationOpen,
    pathname,
    reportOpen,
    settingsOpen,
    toggleAnomaly,
    toggleOptimization,
    toggleReport,
    toggleSettings,
  ]);

  const {
    currentRole,
    curExists,
    showOverview,
    isTrialExpired,
    cmpIntegrated,
  } = useCurrentInfo();
  const settingsItems = useMemo<Array<{ path: string; label: string }>>(() => {
    const items: Array<{ path: string; label: string }> = [
      { path: ROUTES.SETTINGS.PROFILE, label: 'Profile' },
      { path: ROUTES.SETTINGS.ACCOUNTS, label: 'Accounts' },
    ];
    if (currentRole < 3) {
      items.push({ path: ROUTES.SETTINGS.USERS, label: 'Users' });
      if (curExists && !cmpIntegrated) {
        items.push({ path: ROUTES.SETTINGS.PAYMENT, label: 'Payment' });
      }
      items.push({ path: ROUTES.SETTINGS.INTEGRATION, label: 'Integration' });
    }
    return items;
  }, [currentRole, curExists, cmpIntegrated]);
  return (
    <List component="nav" disablePadding>
      <DefaultNavItem
        label={showOverview ? 'Overview' : 'Onboarding'}
        iconName="Overview"
        onNavigate={navigateTo}
        path={ROUTES.OVERVIEW.ROOT}
      />
      {cmpIntegrated && currentRole < 3 && (
        <CollapsibleNavItem
          isOpen={reportOpen}
          label="Report"
          iconName="Billing"
          onToggle={toggleReport}
          onNavigate={navigateTo}
          navItems={[
            { path: ROUTES.USAGE.ROOT, label: 'Cloud Cost' },
            { path: ROUTES.BILLING.ROOT, label: 'Billing' },
          ]}
        />
      )}
      {showOverview && !isTrialExpired && (
        <CollapsibleNavItem
          isOpen={optimizationOpen}
          label="Optimization"
          iconName="Optimization"
          onToggle={toggleOptimization}
          onNavigate={navigateTo}
          navItems={[
            { path: ROUTES.COMPUTE.UTIL, label: 'EC2' },
            { path: ROUTES.DATABASE.UTIL, label: 'RDS' },
            { path: ROUTES.STORAGE.ROOT, label: 'S3' },
            { path: ROUTES.VOLUME.ROOT, label: 'EBS' },
            { path: ROUTES.MISCELLANEOUS.ROOT, label: 'Miscellaneous' },
          ]}
        />
      )}
      {curExists && !isTrialExpired && (
        <>
          <CollapsibleNavItem
            isOpen={anomalyOpen}
            label="Anomaly Detection"
            iconName="AnomalyDetection"
            onToggle={toggleAnomaly}
            onNavigate={navigateTo}
            navItems={[
              { path: ROUTES.ANOMALY.SUMMARY, label: 'Summary' },
              { path: ROUTES.ANOMALY.SETTINGS, label: 'Settings' },
            ]}
          />
          <DefaultNavItem
            label="Smart Planner"
            iconName="SmartPlanner"
            onNavigate={navigateTo}
            path={ROUTES.PLANNER.UNCOVERED}
          />
        </>
      )}
      <CollapsibleNavItem
        isOpen={settingsOpen}
        label="Settings"
        iconName="Settings"
        onToggle={toggleSettings}
        onNavigate={navigateTo}
        navItems={settingsItems}
      />
      {currentRole === 1 && (
        <CollapsibleNavItem
          isOpen={adminOpen}
          label="Admin"
          iconName="Admin"
          onToggle={toggleAdmin}
          onNavigate={navigateTo}
          navItems={[
            { path: ROUTES.ADMIN.COMPANIES, label: 'Companies' },
            { path: ROUTES.ADMIN.SUBSCRIPTIONS, label: 'Subscriptions' },
          ]}
        />
      )}
    </List>
  );
};
MainSidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MainSidebar;
