import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import { useAnalytics, useQueryClientDefaultOptions } from '@hooks/common';
import { AuthLayout, PureAuthLayout, MainLayout } from '@layouts';
import {
  UpdatePasswordPage,
  SignInPage,
  SignUpPage,
  VerifyCompanyPage,
  ResetPasswordPage,
  ResendVerificationPage,
  CMPAccessPage,
} from '@pages/public';
import {
  OptimizationDatabasePage,
  OptimizationStoragePage,
  OverviewPage,
  RunDiagnosticPage,
  ConnectAWSPage,
  OptimizationComputePage,
  ConfigureCURPage,
  DiagnosticReportPage,
  DatabasePurchasingPage,
  DatabaseStoragePage,
  DatabaseUtilizationPage,
  OptimizationVolumePage,
  DetailsComputePage,
  ComputeUtilizationPage,
  ComputePurchasingPage,
  ComputeTransferPage,
  ComputeOtherPage,
  SettingsProfilePage,
  SettingsPaymentPage,
  SettingsIntegrationPage,
  AdminCompanyPage,
  AdminSubscriptionPage,
  AnomalySummaryPage,
  AnomalySettingsPage,
  PlannerUncoveredPage,
  PlannerReservedPage,
  PlannerCommittedPage,
  PlannerSimulationPage,
  DetailsDatabasePage,
  PlannerPage,
  OptimizationMiscellaneousPage,
  SettingsAccountPage,
  SettingsAccountDetailPage,
  NotificationPage,
  SettingsUserPage,
  ExternalAccountPage,
  DetailsStoragePage,
  DetailsVolumePage,
  SettingsAccountOrganizationPage,
  BillingReportPage,
  BillingReportDetailPage,
  UsageReportPage,
} from '@pages/private';
import { PageNotFoundPage } from '@pages/common';

const Pages: React.FC = () => {
  useAnalytics();
  useQueryClientDefaultOptions();
  return (
    <Routes>
      <Route index element={<Navigate to="overview" replace />} />
      <Route element={<AuthLayout />}>
        <Route path="signin" element={<SignInPage />} />
        <Route path="signup" element={<SignUpPage />} />
      </Route>
      <Route path="auth" element={<PureAuthLayout />}>
        <Route path="forgot-password" element={<ResetPasswordPage />} />
        <Route path="reset-password/:token" element={<UpdatePasswordPage />} />
        <Route path="resend" element={<ResendVerificationPage />} />
      </Route>
      <Route path="overview" element={<OverviewPage />} />
      <Route element={<MainLayout />}>
        <Route path="onboard">
          <Route path="connect" element={<ConnectAWSPage />} />
          <Route path="diagnostic">
            <Route index element={<RunDiagnosticPage />} />
            <Route path="report" element={<DiagnosticReportPage />} />
          </Route>
          <Route path="cur/:uid" element={<ConfigureCURPage />} />
        </Route>
        <Route path="optimization">
          <Route path="compute" element={<OptimizationComputePage />}>
            <Route path="utilization" element={<ComputeUtilizationPage />} />
            <Route path="purchasing" element={<ComputePurchasingPage />} />
            <Route path="transfer" element={<ComputeTransferPage />} />
            <Route path="other" element={<ComputeOtherPage />} />
            <Route index element={<Navigate to="./utilization" />} />
          </Route>
          <Route path="database" element={<OptimizationDatabasePage />}>
            <Route path="utilization" element={<DatabaseUtilizationPage />} />
            <Route path="purchasing" element={<DatabasePurchasingPage />} />
            <Route path="storage" element={<DatabaseStoragePage />} />
            <Route index element={<Navigate to="./utilization" />} />
          </Route>
          <Route path="storage" element={<OptimizationStoragePage />} />
          <Route path="volume" element={<OptimizationVolumePage />} />
          <Route
            path="miscellaneous"
            element={<OptimizationMiscellaneousPage />}
          />
          <Route index element={<Navigate to="./compute" />} />
        </Route>
        <Route path="details">
          <Route path="compute/:instanceId" element={<DetailsComputePage />} />
          <Route
            path="database/:databaseId"
            element={<DetailsDatabasePage />}
          />
          <Route path="storage/:bucketName" element={<DetailsStoragePage />} />
          <Route path="volume/:volumeId" element={<DetailsVolumePage />} />
        </Route>
        <Route path="anomaly">
          <Route path="summary" element={<AnomalySummaryPage />} />
          <Route path="settings" element={<AnomalySettingsPage />} />
        </Route>
        <Route path="planner" element={<PlannerPage />}>
          <Route path="uncovered" element={<PlannerUncoveredPage />} />
          <Route path="reserved" element={<PlannerReservedPage />} />
          <Route path="committed" element={<PlannerCommittedPage />} />
          <Route index element={<Navigate to="./uncovered" />} />
        </Route>
        <Route path="planner/simulation">
          <Route
            path="uncovered/:instanceId"
            element={<PlannerSimulationPage />}
          />
        </Route>
        <Route path="settings">
          <Route path="profile" element={<SettingsProfilePage />} />
          <Route path="accounts">
            <Route index element={<SettingsAccountPage />} />
            <Route
              path="organizations"
              element={<SettingsAccountOrganizationPage />}
            />
            <Route
              path="details/:uid"
              element={<SettingsAccountDetailPage />}
            />
          </Route>
          <Route path="users" element={<SettingsUserPage />} />
          <Route path="payment" element={<SettingsPaymentPage />} />
          <Route path="integration" element={<SettingsIntegrationPage />} />
          <Route index element={<Navigate to="./profile" />} />
        </Route>
        <Route path="admin">
          <Route path="companies" element={<AdminCompanyPage />} />
          <Route path="subscriptions" element={<AdminSubscriptionPage />} />
          <Route index element={<Navigate to="./companies" />} />
        </Route>
        <Route path="notifications" element={<NotificationPage />} />
        <Route path="report">
          <Route path="billing">
            <Route path=":id" element={<BillingReportDetailPage />} />
            <Route index element={<BillingReportPage />} />
          </Route>
          <Route path="usage" element={<UsageReportPage />} />
        </Route>
      </Route>
      <Route path="verify/:id" element={<VerifyCompanyPage />} />
      <Route path="external">
        <Route path="master" element={<ExternalAccountPage />} />
        <Route path="linked" element={<ExternalAccountPage />} />
        <Route path="cmp/:hash" element={<CMPAccessPage />} />
      </Route>
      <Route path="*" element={<PageNotFoundPage />} />
    </Routes>
  );
};

export default Pages;
