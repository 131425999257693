import React, { ComponentType, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useCurrentCompany } from '@hooks/api/common';

const WithCMP = <P extends Record<string, unknown>>(
  Component: ComponentType<P>,
): React.FC<P> =>
  function (props) {
    const [approved, setApproved] = useState(false);
    const { data: company } = useCurrentCompany();
    const navigate = useNavigate();
    useEffect(() => {
      if (company?.items?.length) {
        if (_.isEmpty(company.items[0].cmp_id)) {
          navigate(-1);
        } else {
          setApproved(true);
        }
      }
    }, [navigate, company?.items]);
    if (!approved) {
      return null;
    }
    return <Component {...props} />;
  };

export default WithCMP;
