import React, { useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Text,
  XAxis,
  YAxis,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useOverviewSummary, useMonthToDate } from '@hooks/api/overview';
import { bluegrey, primary } from '@theme/colors';
import { ValueFormatter } from '@utils';

type SummaryChartData = {
  Name: string;
  Value: number | null;
};

const COLORS = [bluegrey[200], bluegrey[500], primary[600]] as const;
const TEXT_COLORS = [bluegrey[400], bluegrey[600], bluegrey[900]] as const;

const SavingsSummaryChart: React.FC = () => {
  const { t } = useTranslation('overview');
  const [chartData, setChartData] = useState<
    [SummaryChartData, SummaryChartData, SummaryChartData]
  >([
    {
      Name: t('label.savingsSummary.lastCost'),
      Value: null,
    },
    {
      Name: t('label.savingsSummary.forecasted'),
      Value: null,
    },
    {
      Name: t('label.savingsSummary.newCost'),
      Value: null,
    },
  ]);
  const { isSuccess, data: response } = useMonthToDate();
  const { data } = useOverviewSummary();
  useEffect(() => {
    if (data?.items?.length && chartData[1].Value) {
      setChartData((v) => {
        const clone = _.cloneDeep(v);
        clone[2].Value = Math.max(
          chartData[1].Value - data.items[0].MonthlyPossibleSavingCost,
          0,
        );
        return clone;
      });
    }
  }, [data?.items, chartData[1].Value]);
  useEffect(() => {
    if (isSuccess && response?.items?.length) {
      const costResults = response.items[0];
      const lastMonthCost = _.maxBy(costResults.LastUsageResults, (v) =>
        Number(v?.Cost),
      )?.Cost;
      let thisMonthCost: number | null | undefined = null;
      if (costResults.ForecastResults.length) {
        thisMonthCost = _.maxBy(costResults.ForecastResults, (v) =>
          Number(v?.Cost),
        )?.Cost;
      } else if (costResults.UsageResults.length) {
        thisMonthCost = _.maxBy(costResults.UsageResults, (v) =>
          Number(v?.Cost),
        )?.Cost;
      }
      setChartData((v) => {
        const clone = _.cloneDeep(v);
        clone[0].Value =
          typeof lastMonthCost === 'number' ? lastMonthCost : null;
        clone[1].Value =
          typeof thisMonthCost === 'number' ? thisMonthCost : null;
        return clone;
      });
    }
  }, [isSuccess, response?.items]);
  return (
    <ResponsiveContainer width="100%" height={200}>
      <BarChart
        layout="vertical"
        data={chartData}
        barCategoryGap={16}
        margin={{ left: 156, right: 128 }}
      >
        <XAxis type="number" hide />
        <YAxis
          type="category"
          dataKey="Name"
          axisLine={false}
          tickLine={false}
          tick={({ y, payload: { value } }) => (
            <Text
              x={0}
              y={y}
              fontSize="0.875rem"
              textAnchor="start"
              verticalAnchor="middle"
            >
              {value}
            </Text>
          )}
        />
        <Bar
          dataKey="Value"
          isAnimationActive={false}
          barSize={40}
          maxBarSize={40}
          label={({ x: xValue, y, width: w, height, value, index }) => {
            const width = Number.isNaN(w) ? 0.0 : w;
            const x = Number.isNaN(xValue) ? 0.0 : xValue;
            let textX = x + width + (width > 0.0 ? 24 : 0);
            if (textX < 200) {
              textX += 224;
            }
            // const textX = 200;
            const textY = y + height / 2;
            return (
              <text
                x={textX}
                y={textY}
                fill={TEXT_COLORS[index]}
                fontSize="1rem"
                fontWeight={500}
                textAnchor="start"
                dominantBaseline="middle"
              >
                {ValueFormatter.toCurrency(value) ||
                  t('label.savingsSummary.noCUR')}
              </text>
            );
          }}
          shape={({ x, y, width: w, height, index }) => {
            const width = Number.isNaN(w) ? 0.0 : w;
            if (index === 0) {
              return (
                <rect
                  x={x}
                  y={y + 12}
                  width={width}
                  height={16}
                  fill={COLORS[index]}
                />
              );
            }
            return (
              <rect
                x={x}
                y={y}
                width={width}
                height={height}
                fill={COLORS[index]}
              />
            );
          }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SavingsSummaryChart;
