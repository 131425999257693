import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { useCMPAccess } from '@hooks/api/cmp';
import { ROUTES } from '@routes';

const CMPAccessPage: React.FC = () => {
  const navigate = useNavigate();
  const { hash } = useParams();
  const { mutateAsync } = useCMPAccess();
  const getAccessToken = useCallback(async () => {
    if (hash) {
      try {
        const response = await mutateAsync(hash);
        const items = _.get(response, 'items');
        if (_.isArray(items) && items.length) {
          navigate(ROUTES.OVERVIEW.ROOT, { replace: true });
        } else {
          navigate(ROUTES.AUTH.SIGNIN, { replace: true });
        }
      } catch (e) {
        navigate(ROUTES.AUTH.SIGNIN, { replace: true });
      }
    }
  }, [hash, mutateAsync]);
  useEffect(() => {
    getAccessToken();
  }, [getAccessToken]);
  return null;
};

export default CMPAccessPage;
