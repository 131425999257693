import { useMutation, useQueryClient } from 'react-query';
import _ from 'lodash';
import { CMPService } from '@api/service';
import { GLOBAL, QUERY_KEY } from '@constants';

export default () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: QUERY_KEY.CMP.CMP_ACCESS,
    mutationFn: (variables: string) => CMPService.cmpAccess(variables),
    onSuccess: async (data) => {
      const items = _.get(data, 'items');
      if (_.isArray(items) && items.length) {
        const body = data.items[0];
        const token = _.get(body, 'access_token');
        const expired = _.get(body, 'access_token_expired');
        if (_.isString(token)) {
          localStorage.setItem(GLOBAL.ACCESS_TOKEN, token);
        }
        if (_.isNumber(expired)) {
          localStorage.setItem(GLOBAL.ACCESS_TOKEN_EXPIRED, expired.toString());
        }
        await queryClient.cancelQueries();
        queryClient.clear();
      }
    },
  });
};
